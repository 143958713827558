import React from "react";
import {
  Box,
  ListItem,
  Heading,
  Text,
  UnorderedList,
  Image,
  Flex,
} from "@chakra-ui/react";
import "../style/Depistage.css";
import Header from "../components/Nav";
import Footer from "../components/Footer";
import banniere from "../images/banniere-logiciel.png";
import logoZeus from "../images/zeus.svg";
import logoEligibilite from "../images/logoEligibilite.png";
import kisano from "../images/Partenaire/logo_kisano_w.svg";

function Depistage() {
  return (
    <>
      <Header />
      <Box className="content" backgroundColor={"#02011E"}>
        <Box className="depistageBackground" width={"100%"} height={"auto"}>
          <Image src={banniere} width={"100%"} height={"auto"} alt="" />
        </Box>

        <Box className="solutionContent" id="home">
          <Box className="title">
            <hr />
            <Heading as={"h2"} size={"lg"} mb={"15px"}>
              Logiciel métier dedié au Centre régional de dépistage des cancers
            </Heading>
          </Box>

          <Text>
            Zeus, est un logiciel de gestion des dépistages organisées des
            cancers en France métropolitaine et Outre-Mer. Ce logiciel répond au
            cahier des charges nationales de l’INCA. Notre solution informatique
            permet aux centres régionaux de coordination des dépistages des
            cancers la gestion des différents dépistages (Dépistage du Cancer du
            Sein, Colon, Col de l’Utérus et Poumon). Nous mettons à disposition
            des outils de qualité permettant de répondre aux missions des CRCDC.
          </Text>
          <Flex
            justifyContent={"space-between"}
            mt={"100px"}
            flexDirection={{ base: "column", xl: "row" }}
          >
            <Box className="fonctionalite">
              <Heading as={"h3"} size={"md"} mb={"15px"}>
                Les fonctionnalités de Zeus :
              </Heading>
              <UnorderedList>
                <ListItem>
                  Gestion des intégrations de la population éligible dans le
                  cadre des dépistages organisé
                </ListItem>
                <ListItem>Gestion des invitations</ListItem>
                <ListItem>Gestion administratives des retours</ListItem>
                <ListItem>
                  Gestion des saisies dans le cadre du dépistage organisé du
                  Sein :
                  <UnorderedList>
                    <ListItem>
                      Organisation des séances de de secondes lecture
                    </ListItem>
                    <ListItem>
                      Saisie des seconds lecteurs sur écran tactile
                    </ListItem>
                    <ListItem>
                      Intégration LAD des fiches d’interprétations
                    </ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>
                  Gestion des intégrations dans le cadre du dépistage du colon (
                  Intégration Cerba )
                </ListItem>
                <ListItem>
                  Gestion des intégration dans le cadre du dépistage organisé du
                  Col de l’Utérus :
                  <UnorderedList>
                    <ListItem>Intégration ACTE de l’assurance maladie</ListItem>
                    <ListItem>
                      Intégration ACP des laboratoires Anapath
                    </ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>Gestion des relances de suivie des positifs</ListItem>
                <ListItem>Gestion des saisies des examens de suivi</ListItem>
                <ListItem>Gestion des statistiques</ListItem>
                <ListItem>
                  Gestion des envois de résultats via des plateformes ( Enovacom
                  / Apycript / SISRA etc )
                </ListItem>
                <ListItem>
                  Gestion des envoies de résultats impression papier
                </ListItem>
                <ListItem>
                  Gestion des envoies des fichiers pour Santé Publique France
                </ListItem>
                <ListItem>Gestion du mammobile</ListItem>
              </UnorderedList>
            </Box>
            <Box className="img" pt={{ base: "40px", xl: "initial" }}>
              <Image
                boxSize={"400px"}
                align={"center"}
                src={logoZeus}
                alt="icon Zeus"
                width={"400px"}
              />
            </Box>
          </Flex>
          <Flex
            mt={"100px"}
            justifyContent={"space-between"}
            flexDirection={{ base: "column", xl: "row" }}
          >
            <Box className="img">
              <Text fontSize="4xl" fontWeight={600} wra>
                Service Régional d'Éligibilité <br />
                pour le Dépistage des Cancers
              </Text>
            </Box>
            <Box>
              <Heading as={"h3"} size={"md"} mb={"15px"} mt={"0"}>
                Module d’éligibilité
              </Heading>
              <UnorderedList>
                <ListItem>Module complémentaire à la solution Zeus</ListItem>
                <ListItem>
                  Connexion par carte CPS ou long et mot de passe ( double
                  authentification )
                </ListItem>
                <ListItem>Vérification de l’éligibilité du patient</ListItem>
                <ListItem>Possibilité de compléter le dossier</ListItem>
                <ListItem>Saisi des exclusions</ListItem>
                <ListItem>Impression des documents d’invitations</ListItem>
                <ListItem>Demande de remise de test</ListItem>
                <ListItem>
                  Consultation de suivi médical en cas de dossier positifs
                </ListItem>
                <ListItem>
                  Consultation de la traçabilité de l’utilisateur
                </ListItem>
              </UnorderedList>
            </Box>
          </Flex>
          <Flex
            mt={"100px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDirection={{ base: "column", xl: "row" }}
          >
            <Box>
              <Heading as={"h3"} size={"md"} mb={"15px"} mt={"0"}>
                Module de saisie des mammographies dématérialisée dans les
                cabinets de radiologie{" "}
              </Heading>
              <UnorderedList>
                <ListItem>Partenariat avec Kisano </ListItem>
                <ListItem>Logiciel de saisie de la première lecture</ListItem>
                <ListItem>Transfert des images vers le CRCDC</ListItem>
                <ListItem>
                  Organisation des séances de secondes lectures sur console
                </ListItem>
                <ListItem>
                  {" "}
                  Logiciel de saisie des secondes lectures sur console avec
                  affichage automatique des clichés
                </ListItem>
                <ListItem>
                  Communication avec les plateformes régionales et transfert des
                  images sur ces plateformes
                </ListItem>
              </UnorderedList>
            </Box>
            <Box className="img" pt={{ base: "40px", xl: "initial" }}>
              <Image
                boxSize={"400px"}
                align={"center"}
                src={kisano}
                alt="icon eligibilité"
                width={"400px"}
                height={"auto"}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
      <>
        <Footer />
      </>
    </>
  );
}
export default Depistage;
